import router from '../../route/routes.js';
const state = {
    business_inquiry_id: null,
}

const actions = {
    setBusinessInquiryId({commit}, payload) {
        commit('SET_BUSINESS_INQUIRY_ID', payload)
    }
}

const mutations = {
    'SET_BUSINESS_INQUIRY_ID'(state, payload) {
        state.business_inquiry_id = payload
    },
}

const getters = {
    BUSINESS_INQUIRY_ID: state => {
        return state.business_inquiry_id
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}
