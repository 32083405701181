import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(Router);
Vue.use(VueMeta);

// Coming soon page
// const ComingSoon = () => import(/* webpackChunkName: "ComingSoon" */ '@/components/coming-soon/UnderDevelopment.vue')

// Homepage
const HomePage = () => import(/* webpackChunkName: "Home" */ '@/pages/new-home/Homepage.vue');

// Tracking / Lacak Pengiriman
const Tracking = () => import(/* webpackChunkName: "Tracking" */ '@/pages/new-tracking/Tracking.vue');

// Check Price / Cek Tarif
const CheckRates = () => import(/* webpackChunkName: "CheckPrice" */ '@/pages/new-check-price/CheckPrice.vue');

// Daftar Agen Homepage
const AgentHome = () => import(/* webpackChunkName: "AgentPage22" */ '@/pages/new-agent/Agent.vue');
const AgentLocation = () => import(/* webpackChunkName: "AgentLocation" */ '@/pages/new-agent-location/AgentLocation.vue');

// Daftar Korporasi Homepage
const Corporation = () => import(/* webpackChunkName: "CorporationPage" */ '@/pages/new-corporation/Corporation.vue');
const CorporationForm = () => import(/* webpackChunkName: "CorporationPage" */ '@/pages/new-corporation/form/Form.vue');

const RegisterIndividualAgent = () => import(/* webpackChunkName: "AgentRegisterIndividual" */ '@/pages/new-agent/form/individual/Form.vue');
const RegisterCompanyAgent = () => import(/* webpackChunkName: "AgentRegisterCompany" */ '@/pages/new-agent/form/company/Form.vue');
// const SuccessRegisterAgent = () => import(/* webpackChunkName: "AgentSuccessRegister" */ '@/pages/agent/registerAgent/SuccessRegister.vue');
const NewSuccessRegisterAgent = () => import(/* webpackChunkName: "AgentSuccessRegister" */ '@/pages/new-agent/form/success/Success.vue');

const AboutPage = () => import(/* webpackChunkName: "About" */ '@/pages/new-about/AboutUspage.vue');
const BoardPage = () => import(/* webpackChunkName: "About" */ '@/pages/new-about/board/BoardPage.vue');
const NewCareerPage = () => import(/* webpackChunkName: "Career" */ '@/pages/new-career/Career.vue');
const NewCareerListPage = () => import(/* webpackChunkName: "CareerList" */ '@/pages/new-career/jobs/Jobs.vue');
const NewDetailCareerPage = () => import(/* webpackChunkName: "CareerDetail" */ '@/pages/new-career/detail/Detail.vue');
const NewCareerForm = () => import(/* webpackChunkName: "CareerForm" */ '@/pages/new-career/form/Form.vue');

const NewProgramPage = () => import(/* webpackChunkName: "Promo" */ '@/pages/new-program/Program.vue');
const NewDetailPromoPage = () => import(/* webpackChunkName: "Promo" */ '@/pages/new-program/detail/Detail.vue');

const DetailClaimPage = () => import(/* webpackChunkName: "Claim" */ '@/pages/new-claim/tracking-claim/index.vue');
const NewClaimPage = () => import(/* webpackChunkName: "Claim" */ '@/pages/new-claim/term-condition/index.vue');
const NewFormClaimPage = () => import(/* webpackChunkName: "Claim" */ '@/pages/new-claim/form-claim/index.vue');
const NewSuccessClaimPage = () => import(/* webpackChunkName: "Claim" */ '@/pages/new-claim/success-claim/index.vue');

const ProductPage = () => import(/* webpackChunkName: "Product" */ '@/pages/new-product/Productpage.vue');
const DetailProductPage = () => import(/* webpackChunkName: "Product" */ '@/pages/new-product/product-details/ProductDetailPage.vue');


const TNCPage = () => import(/* webpackChunkName: "Others" */ '@/pages/tnc/TermConditionPage.vue');
const PolicyPage = () => import(/* webpackChunkName: "Others" */ '@/pages/policy/PolicyPage.vue');
const SKPage = () => import(/* webpackChunkName: "Others" */ '@/pages/sk/SKPage.vue');
const FAQPage = () => import(/* webpackChunkName: "FAQNew" */ '@/pages/new-faq/FAQPage.vue');
const detailFAQPage = () => import(/* webpackChunkName: "DetailFAQ" */ '@/pages/new-faq/detail/detailFAQPage.vue');

const DangerousGoodsPage = () => import(/* webpackChunkName: "DangerousGoods" */ '@/pages/new-dangerous-goods/DangerousGoodsPage.vue');

const MerchantPage = () => import(/* webpackChunkName: "Merchant" */ '@/pages/new-merchant/Merchant.vue');
const FormMerchant = () => import(/* webpackChunkName: "Merchant" */ '@/pages/new-merchant/form/Form.vue');
const MerchantDetail = () => import(/* webpackChunkName: "Merchant" */ '@/pages/new-merchant/detail/Detail.vue');
const ProductDetail = () => import ('@/pages/new-merchant/product-detail/ProductDetail.vue');

const KarantinaPage = () => import(/* webpackChunkName: "Karantina" */ '@/pages/new-quarantine/Karantina.vue');

const LILOPage = () => import(/* webpackChunkName: "LILO" */ '@/pages/new-lilo/lilo.vue');
const LILOPageSuccess = () => import(/* webpackChunkName: "LILO Success" */ '@/pages/new-lilo/success.vue');

const CODPage = () => import(/* webpackChunkName: "COD" */ '@/pages/new-cod/cod.vue');

// this page gonna be use for Mobile App to have shortener link
const ShortenerLink = () => import(/* webpackChunkName: "ShortenerLink" */ '@/pages/shortenerLink/shortenerLink.vue');

const RegisterPage = () => import('@/pages/new-register/Register.vue');

const AllMerchant = () => import('@/pages/new-merchant/all');
const FeedbackPage = () => import(/* webpackChunkName: "OlehOleh" */ '@/pages/shop/feedback/FeedbackPage.vue');
const FeedbackPage2 = () => import(/* webpackChunkName: "OlehOleh" */ '@/pages/shop/feedback/FeedbackPage2.vue');
const EventAndTips = () => import(/* webpackChunkName: "EventAndTips" */ '@/pages/new-event-and-tips/EventAndTips.vue');
const EventAndTipsDetail = () => import(/* webpackChunkName: "EventAndTips" */ '@/pages/new-event-and-tips/detail/Detail.vue');
const MembershipSK = () => import(/* webpackChunkName: "MembershipSK" */ '@/pages/sk/membership/index.vue');
const TermAndConditions = () => import(/* webpackChunkName: "TermAndConditionsPageMobileApp" */ '@/pages/mobile-app/terms-and-conditions/TermsAndConditions.vue')

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        component: HomePage,
        name: 'home',
        meta: {
          auth: false, type: '',
        },
      },
      {
        path: '/faq',
        component: () => import(/* webpackChunkName: "FAQNew" */ '@/pages/new-faq'),
        children: [
          { path: '/faq', component: FAQPage },
          {
            path: '/faq/:category',
            redirect: { path: '/faq/bantuan/jenis-pengiriman-&-layanan', query: { page: 1 } },
          },
          {
            path: '/faq/:category/:topic', component: detailFAQPage, props: true, name: ':topic',
          },
        ],
        name: 'FAQ',
        meta: {
          auth: false, type: '',
        },

      },
      {
        path: '/agen',
        name: 'Agen',
        component: {
          render: c => c('router-view'),
        },
        children: [
          { path: '/agen', component: AgentHome },
          { path: 'perorangan-app', component: RegisterIndividualAgent, name: 'Daftar Perorangan' },
          { path: 'perusahaan-app', component: RegisterCompanyAgent, name: 'Daftar Perusahaan' },
          {
            path: 'registration-success/result/',
            component: NewSuccessRegisterAgent,
            name: 'successRegisterAgent',
            props: true,
          },
        ],
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/lokasi-agen',
        component: AgentLocation,
        name: 'Lokasi Agen',
        meta: {
          auth: false, type: '',
        },
      },
      {
        path: '/korporasi',
        component: {
          render: c => c('router-view'),
        },
        name: 'Korporasi',
        children: [
          { path: '/korporasi', component: Corporation },
          { path: 'form', component: CorporationForm, name: 'Formulir Bisnis' },
        ],
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/career',
        component: () => import(/* webpackChunkName: "Career" */ '@/pages/new-career'),
        children: [
          { path: '/career', component: NewCareerPage, props: true },
          { path: '/career/jobs', component: NewCareerListPage, props: true },
          // {path: '/career/walk-in', component: CareerPageInterview, props: true},
          {
            path: '/career/detail/:career_id/:position', component: NewDetailCareerPage, props: true, name: ':position',
          },
          {
            path: '/career/form/:career_id', component: NewCareerForm, props: true, name: 'Form Lowongan Karir',
          },
        ],
        name: 'Karir',
        meta: {
          auth: false, type: '',
        },
      },
      {
        path: '/about',
        component: () => import(/* webpackChunkName: "About2" */ '@/pages/new-about'),
        children: [
          { path: '/about', component: AboutPage },
          {
            path: '/about/board/:id', component: BoardPage, props: true, name: 'Pimpinan Kami',
          },
        ],
        name: 'Tentang Kami',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/product',
        component: {
          render: c => c('router-view'),
        },
        children: [
          { path: '/product', component: ProductPage },
          {
            path: '/product/:product_name', component: DetailProductPage, props: true, name: 'Detail',
          },
        ],
        name: 'Pengiriman',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/promo',
        component: {
          render: c => c('router-view'),
        },
        children: [
          { path: '/promo', component: NewProgramPage },
          {
            path: '/promo/detail/:program_slug', component: NewDetailPromoPage, props: true, name: ':program_slug',
          },
        ],
        name: 'Promo List',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/oleholeh/feedback', // used on BisaBelanja Apps
        component: () => import(/* webpackChunkName: "OlehOleh" */ '@/pages/shop'),
        children: [
          { path: '/oleholeh/feedback', component: FeedbackPage },
          { path: '/oleholeh/feedback/:feedback_id', component: FeedbackPage2 },
        ],
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/tarif',
        component: CheckRates,
        name: 'Cek Tarif',
        props: true,
        meta: {
          auth: false,
          type: '',
        },
      },
      /* { path: '/tariff', components: DetailCheckPrice, name: 'tariff', props: true }, */
      {
        path: '/track/stt',
        component: Tracking,
        name: 'Lacak Pengiriman',
        props: true,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/klaim',
        component: () => import(/* webpackChunkName: "Claim" */ '@/pages/new-claim'),
        children: [
          { path: '/klaim', component: NewClaimPage, props: true },
          {
            path: '/klaim/form', component: NewFormClaimPage, name: 'Ajukan Klaim Baru', props: true,
          },
          {
            path: '/klaim/success', component: NewSuccessClaimPage, name: 'Sukses Ajukan Klaim Baru', props: true,
          },
          {
            path: '/klaim/track', component: DetailClaimPage, name: 'Lacak Klaim', props: true,
          },
        ],
        name: 'Klaim',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/app/tnc',
        component: TNCPage,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/app/policy',
        component: PolicyPage,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/dangerous-goods',
        name: 'Dangerous Goods',
        component: DangerousGoodsPage,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/sk',
        component: SKPage,
        meta: {
          auth: false,
          type: '',
        },
      },
      { path: '*', redirect: '/' },
      {
        path: '/merchant',
        component: {
          render: c => c('router-view'),
        },
        children: [
          { path: '', component: MerchantPage, name: 'Merchant' },
          // {path: "/merchant/form", component: CreateMerchantPage},
          { path: '/merchant/form', component: FormMerchant, name: 'Pendaftaran Merchant' },
          { path: '/merchant/all', component: AllMerchant },
          { path: '/merchant/detail/:merchant_slug', component: MerchantDetail },
          { path: '/merchant/detail/:merchant_slug/:product_slug', component: ProductDetail },
        ],
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/register',
        component: RegisterPage,
        name: 'Daftar Akun',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/email/verification/:token',
        component: () => import(/* webpackChunkName: "EmailVerification" */ '@/pages/email/index.vue'),
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/karantina',
        name: 'Karantina',
        component: KarantinaPage,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/lilo',
        name: 'Fulfillment',
        component: () => import(/* webpackChunkName: "LILO" */ '@/pages/new-lilo/'),
        children: [
            { path: '/lilo', component: LILOPage, props: true},
            { path: '/lilo/success', component: LILOPageSuccess, props: true, name: "liloSuccess"}
        ],
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/cod',
        name: 'COD',
        component: CODPage,
        meta: {
            auth: false,
            type: '',
        },
      },
      {
        path: '/info-mitra',
        component: () => import(/* webpackChunkName: "EventAndTipsInfoMitra" */ '@/pages/new-event-and-tips'),
        children: [
          { path: '/info-mitra', component: EventAndTips },
          {
            path: '/info-mitra/:corporate_slug', name: ':corporate_slug_mitra', component: EventAndTipsDetail, props: true,
          },
        ],
        name: 'Info Mitra',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/info-seller',
        component: () => import(/* webpackChunkName: "EventAndTipsInfoSeller" */ '@/pages/new-event-and-tips'),
        children: [
          { path: '/info-seller', component: EventAndTips },
          {
            path: '/info-seller/:corporate_slug', name: ':corporate_slug_seller', component: EventAndTipsDetail, props: true,
          },
        ],
        name: 'Info Seller',
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        // this page gonna be use for Mobile App to have shortener link
        path: '/link/:code',
        name: 'Shortener Link',
        component: ShortenerLink,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        // this page gonna be use for Mobile App to have shortener link
        path: '/app/t&c',
        name: 'Membership SK',
        component: MembershipSK,
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/app/recipient-form/:id',
        name: 'Recipient Form',
        component:  () => import(/* webpackChunkName: "MobileAppRecipientForm" */ '@/pages/mobile-app/recipient-form/RecipientForm.vue'),
        meta: {
          auth: false,
          type: '',
        },
      },
      {
        path: '/app/terms-and-conditions/:term_and_cond_id',
        name: 'Term and Conditions',
        component: TermAndConditions,
        meta: {
            auth: false,
            type: '',
        },
      },
      {
        path: '/*',
        component: () => import(/* webpackChunkName: "PageNotFound" */ '@/pages/not-found/NotFound.vue'),
        meta: {
          auth: false,
          type: '',
        },
      },
    ],
  });
}
export default createRouter();
