import axios from 'axios';
import router from '../../route/routes.js';
const state = {
    claim_id: null,
    claim: {
        claimer_name: "",
        claimer_email: "",
        claimer_phone_number: "",
        claimer_type: "",
        stt_id: "",
        claim_type: "",
        reason: "",
        attachment_photo_ktp_url: null,
        attachment_photo_bank_url: null,
        attachment_photo_packet_url: null,
        attachment_invoice_url: null
    },
    result_claim: null,
    message_claim: '',
    disabled_button_claim: false,
    isLoadingClaim: false,
    is_otopack: false,
}

const actions = {
    submitClaim({ commit }, payload) {
        let form = new FormData();
        form.append("claimer_name", payload.claimer_type);
        form.append("claimer_email", payload.claimer_email);
        form.append("claimer_phone_number", payload.claimer_phone_number);
        form.append("claimer_type", payload.claimer_type);
        form.append("stt_id", payload.stt_id);
        form.append("claim_type", payload.claim_type);
        form.append("reason", payload.reason);
        form.append("attachment_photo_ktp_url", payload.attachment_photo_ktp_url)
        form.append("attachment_photo_bank_url", payload.attachment_photo_bank_url);
        form.append("attachment_photo_packet_url", payload.attachment_photo_packet_url);
        form.append("attachment_invoice_url", payload.attachment_invoice_url);
        let api = process.env.VUE_APP_DOMAIN_URL;
        commit('SET_DISABLED_BUTTON_CLAIM', true);
        axios.post(`${api}/v1/claim`, form, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN_URL,
                "Content-Type": "application/json"
            }

        }).then(res => {
            if (res.status === 200) {
                commit('SET_CLAIM', form);
                commit('SET_CLAIM_ID', res.data.claim_id)
            }
        }).catch((err) => {
            commit('SET_DISABLED_BUTTON_CLAIM', false);
            alert(err.response.data.message.id);

        })

    },
    findClaim({ commit }, payload) {
        const api = process.env.VUE_APP_DOMAIN_URL;
        const token = process.env.VUE_APP_TOKEN_URL
        commit('SET_LOADING_CLAIM', true)
        axios.get(`${api}/v1/claim/${payload.claim_id}`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            if (res.status === 200) {
                const claim = {
                    result_claim: res,
                    claim_id: payload.claim_id
                }
                commit('FIND_CLAIM', claim)
            }
        }).catch((err) => {
            commit("SET_MESSAGE_CLAIM", err.response.data.message.id)

        }).finally(()=>{
            commit('SET_LOADING_CLAIM', false)
        })
    }

}

const mutations = {
    'SET_CLAIM'(state, payload) {
        state.claim.claimer_name = payload.claimer_name
        state.claim.claimer_email = payload.claimer_email
        state.claimer_phone_number = payload.claimer_phone_number
        state.claimer_type = payload.claimer_type
        state.stt_id = payload.stt_id
        state.claim_type = payload.claim_type
        state.reason = payload.reason
        state.attachment_photo_ktp_url = payload.attachment_photo_ktp_url
        state.attachment_photo_bank_url = payload.attachment_photo_bank_url
        state.attachment_photo_packet_url = payload.attachment_photo_packet_url
        state.attachment_invoice_url = payload.attachment_invoice_url
    },
    'SET_CLAIM_ID'(state, payload) {
        state.claim_id = payload
    },
    'FIND_CLAIM'(state, payload) {
        state.claim_id = payload.claim_id;
        state.result_claim = payload.result_claim;
    },
    'SET_MESSAGE_CLAIM'(state, payload) {
        state.message_claim = payload;
        state.result_claim = null;
    },
    'SET_DISABLED_BUTTON_CLAIM'(state, payload) {
        state.disabled_button_claim = payload
    },
    'SET_LOADING_CLAIM'(state, payload) {
        state.isLoadingClaim = payload
    },
    'SET_IS_OTOPACK'(state, payload) {
        state.is_otopack = payload
    },
}


const getters = {
    NEW_CLAIM: state => {
        return state.claim
    },
    RESULT_CLAIM_ID: state => {
        return state.claim_id
    },
    RESULT_CLAIM: state => {
        return state.result_claim
    },
    MESSAGE_CLAIM: state => {
        return state.message_claim
    },
    DISABLED_BUTTON_CLAIM: state => {
        return state.disabled_button_claim
    },
    IS_LOADING_CLAIM : state => {
        return state.isLoadingClaim
    },
    IS_OTOPACK: state => {
        return state.is_otopack
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}
