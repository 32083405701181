const state = {
  promo: {},
  promos: [],
};

const actions = {
  setPromo({ commit }, payload) {
    commit('SET_PROMO', payload);
  },
  setPromos({ commit }, payload) {
    commit('SET_PROMOS', payload);
  },
};

const mutations = {
  SET_PROMO(state, payload) {
    state.promo = payload;
  },
  SET_PROMOS(state, payload) {
    state.promos = payload;
  },
};

const getters = {
  PROMO(state) {
    return state.promo;
  },
  PROMOS(state) {
    return state.promos;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
