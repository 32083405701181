
const state = {
    cart: {
        sender_name: "",
        sender_phone_number: "",
        sender_email: "",
        receiver_name: "",
        receiver_phone_number: "",
        receiver_destination: "",
        receiver_address: "",
        items: [],
        sub_total: 0,
        delivery_fee: 0,
        total_fee: 0
    },
    order_id: "",
    oleh_oleh: {
        answer_one: "",
        answer_two: "",
        answer_three: "",
    },
}

const actions = {
    getCart({ commit }, payload) {
        const cart = {
            items: payload.items,
            sub_total: payload.sub_total,
            delivery_fee: payload.delivery_fee,
            total_fee: payload.total_fee
        }
        commit('SET_CART', cart)
    },
    completingInformationCart({ commit }, payload) {
        const cart = {
            sender_name: payload.sender_name,
            sender_phone_number: "62" + payload.sender_phone_number,
            sender_email: payload.sender_email,
            receiver_name: payload.receiver_name,
            receiver_phone_number: "62" + payload.receiver_phone_number,
            receiver_destination: payload.receiver_destination,
            receiver_address: payload.receiver_address,
        }
        commit('SET_CUSTOMER_TO_CART', cart);
    },
    setOrderIdPayment({ commit }, payload) {
        let id = payload
        commit('SET_ORDER_ID_PAYMENT', id)
    },
    setOlehOleh({commit}, payload) {
        let oleh_oleh = {}
        if (payload === null) {
            oleh_oleh = {
                answer_one : "",
                answer_two : "",
                answer_three : "",
            }
        } else {
            oleh_oleh = {
                answer_one : payload.answer_one,
                answer_two : payload.answer_two,
                answer_three : payload.answer_three,
            }
        }
        commit('SET_OLEH_OLEH', oleh_oleh)
    },
}

const mutations = {
    'SET_CART'(state, payload) {
        state.cart.items = payload.items
        state.cart.sub_total = payload.sub_total
        state.cart.delivery_fee = payload.delivery_fee
        state.cart.total_fee = payload.total_fee
    },
    'SET_CUSTOMER_TO_CART'(state, payload) {
        state.cart.sender_name = payload.sender_name
        state.cart.sender_phone_number = payload.sender_phone_number
        state.cart.sender_email = payload.sender_email
        state.cart.receiver_name = payload.receiver_name
        state.cart.receiver_phone_number = payload.receiver_phone_number
        state.cart.receiver_destination = payload.receiver_destination
        state.cart.receiver_phone_number = payload.receiver_phone_number
        state.cart.receiver_destination = payload.receiver_destination
        state.cart.receiver_address = payload.receiver_address
    },
    'SET_ORDER_ID_PAYMENT'(state, payload) {
        state.order_id = payload
    },
    'SET_OLEH_OLEH'(state, payload) {
        state.oleh_oleh.answer_one = payload.answer_one
        state.oleh_oleh.answer_two = payload.answer_two
        state.oleh_oleh.answer_three = payload.answer_three
    },
}

const getters = {
    CART: state => {
        return state.cart
    },
    ORDER_ID: state => {
        return state.order_id
    },
    OLEH_OLEH: state => {
        return state.oleh_oleh
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}