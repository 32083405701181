import { isSsr } from '@/helpers/DetectSSR';

export default {
  computed: {
    listenWidth() {
      return this.windowWidth;
    },
  },
  data() {
    return {
      /** @type {boolean} */
      isMobileSize: false,
      isTablet: false,
      windowWidth: 0,
    };
  },
  methods: {
    /** @type {void} */
    isMobile() {
      if (!isSsr()) {
        this.isMobileSize = window.innerWidth < 991;
        this.isTablet = window.innerWidth <= 768;
        this.windowWidth = window.innerWidth;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        );
      }
      return false;
    },
    isDesktop() {
      return !this.isMobile();
    },

  },
  created() {
    this.isMobile();
    if (!isSsr()) {
      window.addEventListener('resize', this.isMobile);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.isMobile);
  },
};
