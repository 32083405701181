import InternalAPI from "@/app/baseAxios";
import "@/pages/new-career/app/types";

/**
 * @contructor
 * @extends InternalAPI
 * */ 
export default class CareerRepository extends InternalAPI {
  constructor() {
    super();

    this.careerListEndpoint = "v1/career/data";
    this.careerDetailEndpoint = "v1/career/";
    this.careerSubmitCandidateEndpoint = "v1/candidate";
    this.careerEmployeeBranding = "v1/employee_branding/data";
    this.careerLocation = "v1/location/province/data";
    this.careerDivision = "/v1/division/data";
  }


  /**
   * @param {string} query query param
   * @return {Promise<{data: CareerListResponse | {data: []}, err: ErrorResponse}>}
   * */ 
  async getAll(query) {
    try {
      const res = await this.$axios.get(this.careerListEndpoint + '?' + query);
      if (res.status === 200 && res.data) {
        return {data: res.data, err: null}
      }
      return {data: {data: []}, err: res.data.data}
    } catch (err) {
      return {data: {data: []}, err: this.setErrorMessage(err.response.data.message)}
    }
  }

  /**
   * @param {number} id career id
   * @return {Promise<{data: Career | {}, err: ErrorResponse}>}
   * */ 
  async getDetail(id) {
    try {
      const res = await this.$axios.get(this.careerDetailEndpoint + id)
      if (res.status === 200) {
        return {data: res.data}
      }
      return {data: {}, err: res.data}
    } catch (err) {
      return {data: {}, err: this.setErrorMessage(err.response.data.message)}
    }
  }

  async submitCandidate(payload) {
    try {
      const res = await this.$axios.post(this.careerSubmitCandidateEndpoint, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (res.status === 200) {
        return {data: res.data}
      }
      return {data: {}, err: res.data}
    } catch (err) {
      return {data: {}, err: this.setErrorMessage(err.response.data.message)}
    }
  }

  async getEmployeeBranding() {
    try {
      const res = await this.$axios.get(this.careerEmployeeBranding + '?per_page=5&status=ACTIVE&priority=ASC')
      if (res.status === 200) {
        return {data: res.data, err: null}
      }
      return {data: null, err: null}
    } catch (err) {
      return {data: {}, err: this.setErrorMessage(err.response.data.message)}
    }
  }

  async getLocation() {
    try {
      const res = await this.$axios.get(this.careerLocation)
      if (res.status === 200) {
        return {data: res.data, err: null}
      }
      return {data: { data:[] }, err: null}
    } catch (err) {
      return {data: { data:[] }, err: this.setErrorMessage(err.response.data.message)}
    }
  }

  async getDivision() {
    try {
      const res = await this.$axios.get(this.careerDivision  + '?per_page=100&status=ACTIVE')
      if (res.status === 200) {
        return {data: res.data.data, err: null}
      }
      return {data: { data:[] }, err: null}
    } catch (err) {
      return {data: { data:[] }, err: this.setErrorMessage(err.response.data.message)}
    }
  }
}
