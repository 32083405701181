const state = {
  event: {},
  events: [],
};

const actions = {
  setEvent({ commit }, payload) {
    commit('SET_EVENT', payload);
  },
  setEvents({ commit }, payload) {
    commit('SET_EVENTS', payload);
  },
};

const mutations = {
  SET_EVENT(state, payload) {
    state.event = payload;
  },
  SET_EVENTS(state, payload) {
    state.events = payload;
  },
};

const getters = {
  EVENT(state) {
    return state.event;
  },
  EVENTS(state) {
    return state.events;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
