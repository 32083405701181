import CheckShippingPriceRepository from "../../app/api/checkShippingPrice.js";
import CheckShippingPriceUseCase from "../../app/usecase/checkShippingPrice.js"
import {API_VERSION} from "../../app/api/helper.js";

const ShippingRatesRepository = new CheckShippingPriceRepository()

const state = {
    check_price: {}, // result data to get price
    origin: '', // a prefix code from, ex: 'JBR10'
    destination: '', // a prefix code to, ex: 'KSL24'
    route_code_origin: '', // the name city from, ex: 'BANJARMASIN BARAT, BANJARMASIN (BDJ)'
    route_code_destination: '', // the name city to, ex: 'BANJARAN, BANDUNG (BDS)'
    weight: null, // must a number
    err_check_price: null,
    form_check_shipping_price: {
        from: {
            route_code: "",
            route_id: 0,
            district_code: ""
        },
        to: {
            route_code: "",
            route_id: 0,
            district_code: ""
        },
        weight: "",
    },
    loading: true,
    filled: false,
    /** @type {{data: RoutePlace[], err: Error}} */
    listOrigin: {
        data: [],
        err: null,
    },
    /** @type {{data: RoutePlace[], err: Error}} */
    listDestination: {
        data: [],
        err: null,
    },
}

const actions = {
    async initCheckPrice({commit}, payload) {
        commit('SET_LOADING_CHECK_PRICE', true)
        let tariff = {
            check_price: {
                isNotInternationalShipping: false,
                result: []
            },
            origin: undefined,
            destination: undefined,
            weight: undefined
        };
        commit('SET_CHECK_PRICE', tariff)
        commit('SET_FILED', true)
        commit('SET_ERROR_CHECK_PRICE', null)
        const q = "?" + "origin=" + payload.origin + "&destination=" + payload.destination + "&weight=" + payload.weight
        try {
            const res = await ShippingRatesRepository.getOneResult(q)
            if (res.data) {
                if (API_VERSION === 'v2') {
                    commit('SET_ROUTE_CODE_ORIGIN', payload.route_code_origin)
                    commit('SET_ROUTE_CODE_DESTINATION', payload.route_code_destination)
                }

                res.data = await CheckShippingPriceUseCase.manipulationDataCheckPrice(res.data)

                tariff = {
                    check_price: res.data,
                    origin: payload.origin,
                    destination: payload.destination,
                    weight: payload.weight
                }
                commit('SET_CHECK_PRICE', tariff)
                commit('SET_LOADING_CHECK_PRICE', false)
            } else {
                commit('SET_LOADING_CHECK_PRICE', false)
                commit('SET_ERROR_CHECK_PRICE', res.err)
            }
        } catch (err) {
            commit('SET_LOADING_CHECK_PRICE', false)
            commit('SET_ERROR_CHECK_PRICE', err.message)
        } finally {
            commit('SET_LOADING_CHECK_PRICE', false)
        }
    },
    initFillFormShippingPrice({commit}, payload) {
        commit('SET_FORM_CHECK_SHIPPING_PRICE', payload)
    },
    setListOrigin({commit}, payload) {
        commit('SET_LIST_ORIGIN', payload)
    },
    setListDestination({commit}, payload) {
        commit('SET_LIST_DESTINATION', payload)
    }
}

const mutations = {
    'SET_CHECK_PRICE'(state, payload) {
        state.check_price = payload.check_price
        state.origin = payload.origin
        state.destination = payload.destination
        state.weight = payload.weight
    },
    'SET_ROUTE_CODE_ORIGIN'(state, payload) {
        state.route_code_origin = payload
    },
    'SET_ROUTE_CODE_DESTINATION'(state, payload) {
        state.route_code_destination = payload
    },
    'SET_ORIGIN_TARIFF'(state, payload) {
        state.origin = payload
    },
    'SET_DESTINATION_TARIFF'(state, payload) {
        state.destination = payload
    },
    'SET_ERROR_CHECK_PRICE'(state, payload) {
        state.err_check_price = payload
    },
    'SET_FORM_CHECK_SHIPPING_PRICE'(state, payload) {
        state.form_check_shipping_price = payload
    },
    'SET_LOADING_CHECK_PRICE'(state, payload) {
        state.loading = payload
    },
    'SET_FILED'(state, payload) {
        state.filled = payload
    },
    'SET_LIST_ORIGIN'(state, payload) {
        state.listOrigin = payload
    },
    'SET_LIST_DESTINATION'(state, payload) {
        state.listDestination = payload
    }
}

const getters = {
    CHECK_PRICE: state => {
        return state.check_price;
    },
    ORIGIN: state => {
        return state.origin;
    },
    DESTINATION: state => {
        return state.destination;
    },
    WEIGHT: state => {
        return state.weight;
    },
    ROUTE_CODE_ORIGIN: state => {
        return state.route_code_origin
    },
    ROUTE_CODE_DESTINATION: state => {
        return state.route_code_destination
    },
    GET_ERR_CHECK_PRICE: state => {
        return state.err_check_price
    },
    GET_FORM_CHECK_SHIPPING_PRICE: state => {
        return state.form_check_shipping_price
    },
    GET_LOADING_CHECK_PRICE: state => {
        return state.loading
    },
    GET_FILED: state => {
        return state.filled
    },
    GET_LIST_ORIGIN: state => {
        return state.listOrigin
    },
    GET_LIST_DESTINATION: state => {
        return state.listDestination
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
