const state = {
  merchant: {},
  product: {},
};

const actions = {
  setMerchant({ commit }, payload) {
    commit('SET_MERCHANT', payload);
  },
  setMerchantProduct({ commit }, payload) {
    commit('SET_MERCHANT_PRODUCT', payload);
  },
};

const mutations = {
  SET_MERCHANT(state, payload) {
    state.merchant = payload;
  },
  SET_MERCHANT_PRODUCT(state, payload) {
    state.product = payload;
  },
};

const getters = {
  MERCHANT(state) {
    return state.merchant;
  },
  MERCHANT_PRODUCT(state) {
    return state.product;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
