const state = {
  homeBanner: {
    dataImage: {
      desktop: null,
      mobile: null
    },
    alt_banner: null
  },
};

const actions = {
  setPromo({ commit }, payload) {
    commit('SET_HOME_BANNER', payload);
  },
};

const mutations = {
  SET_HOME_BANNER(state, payload) {
    state.homeBanner = payload;
  },
};

const getters = {
  HOME_BANNER(state) {
    return state.promo;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
