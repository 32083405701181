import Vue from 'vue';
import Vuex from 'vuex';
import checkPrice from './modules/checkPrice';
import tracking from './modules/tracking';
import agent from './modules/agent';
import npos from './modules/npos';
import claim from './modules/claim';
import shop from './modules/shop';
import products from './modules/product';
import career from './modules/career';
import businessInquiry from './modules/businessInquiry';
import eventAndTips from './modules/eventAndTips';
import promo from './modules/promo';
import merchant from './modules/merchant';
import home from './modules/home';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    modules: {
      checkPrice,
      tracking,
      agent,
      npos,
      claim,
      shop,
      products,
      career,
      businessInquiry,
      eventAndTips,
      promo,
      merchant,
      home
    },
  });
}
