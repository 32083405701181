const state = {
  product: {},
};

const mutations = {
  SET_PRODUCT(state, payload) {
    state.product = payload;
  },
};

const getters = {
  PRODUCT: state => {
    return state.product;
  },
};

const actions = {
  setProductDetail({ commit }, payload) {
    commit('SET_PRODUCT', payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
